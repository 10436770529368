const { getUrlParams } = require('./urlParams')
const { ylkStringToObj } = require('./rapid')

const NCID_PATTERN = /^[\d\w-]+$/

class NcidForwarder {
  constructor() {
    this.trackedLinksSelectors = []
    this.ncid = this._getNcid()

    // Do nothing if there is no NCID in the URL or it is a bot crawling the page
    if (window.AOL?.isBot) {
      return
    }

    this._addEventListeners()
  }

  _getNcid() {
    const urlParams = getUrlParams()
    const ncid = urlParams.get('ncid')
    return NCID_PATTERN.test(ncid) ? ncid : null
  }

  _addEventListeners() {
    window.addEventListener('click', this._handelClickEvent.bind(this), { capture: true })
  }

  _handelClickEvent(e) {
    const { target } = e
    const trackedLink = target.closest(this.trackedLinksSelectors.join(','))

    // If link is not a tracked link, do nothing
    if (!trackedLink) {
      return
    }

    let linkHasValidHref = false
    try {
      // Checking if the href is a valid URL, will throw an error if it is not
      const urlObj = new URL(trackedLink.href)
      if (this._isAolDomain(urlObj.hostname) || this._isYahooDomain(urlObj.hostname)) {
        linkHasValidHref = true
      }
    } catch (err) {
      linkHasValidHref = false
    }

    // If link does not have a valid href, do nothing
    if (!linkHasValidHref) {
      return
    }

    e.preventDefault()
    e.stopPropagation()

    if (window.rapidInstance) {
      this._rapidBeacon(trackedLink, () => {
        this._navigateToUrl(trackedLink, e)
      })
    } else {
      this._navigateToUrl(trackedLink, e)
    }
  }

  _rapidBeacon(link, callback = () => {}) {
    // Implement rapid beacon
    const rapidModule = link.closest("[data-rapid='true']")

    // This link is not being rapid tracked so continue to callback
    if (!rapidModule || !window.rapidInstance.isModuleTracked(rapidModule.id)) {
      callback()
      return
    }

    const moduleYlkString = rapidModule.getAttribute('data-ylk') || ''
    const linkYlkString = link.getAttribute('data-ylk') || ''

    const moduleYlk = ylkStringToObj(moduleYlkString)
    const linkYlk = ylkStringToObj(linkYlkString)

    const ylk = { ...moduleYlk, ...linkYlk }

    // Add target and target uri to ylk object if not present
    // This is needed as the beaconClick function does not auto add these values, like the rapid auto tracking does
    if (!ylk.tar || !ylk.tar_uri) {
      const url = new URL(link.href)
      ylk.tar = encodeURIComponent(url.hostname)
      ylk.tar_uri = encodeURIComponent(url.pathname)
    }

    window.rapidInstance.beaconClick(
      ylk.sec,
      ylk.slk || link.textContent,
      link.dataset.rapid_p || 0,
      ylk,
      'Interaction',
      callback
    )
  }

  _navigateToUrl(link, event) {
    const { href } = link
    const url = new URL(href)

    if (this.ncid) {
      // If the URL already has an NCID, don't change it
      if (!url.searchParams.has('ncid')) {
        url.searchParams.set('ncid', this.ncid)
      }
    }

    if (url.searchParams.has('dest')) {
      url.searchParams.set('dest', window.location.href)
    }

    let target = link.target || '_self'
    // if shift, cmd or ctrl key is pressed, open in new
    if (event.metaKey || event.ctrlKey || event.shiftKey) {
      target = '_blank'
    }

    window.open(url.toString(), target)
  }

  _isAolDomain(hostname = '') {
    if (!hostname) {
      return false
    }
    const tlds = ['com', 'co.uk', 'de', 'ca', 'it']
    const internalDomainPattern = new RegExp(`.aol.(${tlds.join('|')})$`)
    return internalDomainPattern.test(hostname)
  }

  _isYahooDomain(hostname = '') {
    if (!hostname) {
      return false
    }
    const tlds = ['com']
    const internalDomainPattern = new RegExp(`.yahoo.(${tlds.join('|')})$`)
    return internalDomainPattern.test(hostname)
  }

  addTrackedLinksSelector(selector) {
    this.trackedLinksSelectors.push(selector)
  }

  removeTrackedLinksSelector(selector) {
    this.trackedLinksSelectors = this.trackedLinksSelectors.filter(s => s !== selector)
  }
}

module.exports = NcidForwarder
